
<template>
  <div class="evidence_upload">

    <div class="infox">
      <a class="button-text right text-red-700" v-if="isSuccess" v-on:click="clear()">
          <i class="icon fas fa-trash-alt"/> Replace file
      </a>
  </div>


      <!--UPLOAD-->
       <form enctype="multipart/form-data" novalidate v-if="isInitial || isSaving" v-on:click="openBrowse()">
        <div class="bg-blue-100 border-dashed border-2 p-4 rounded hover:opacity-75 cursor-pointer" >
          <input type="file" multiple :name="uploadFieldName" :disabled="isSaving" @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length" accept="video/*|image/*" class="input-file text-base cursor-pointer" ref="upBtn">
            <h4 v-if="isInitial" class="text-base md:text-lg font-semibold text-red-400 p-4 uppercase text-center">
              Drag your file(s) here to begin or click to browse
                <i class="fa fa-plus-circle text-3xl text-red-400 cursor-pointer p-1" />
            </h4>
           
            <p v-if="isSaving">
              Uploading file...
            </p>
        </div>
       </form>

      <div class="feedback" v-if="isSuccess || isFailed">

     <!--SUCCESS-->
      <div v-if="isSuccess" class="success">
        <span class="uploaded-msg"> <i class="icon fa-check"/> 1 file has been uploaded</span>
        <i  v-show="loading" class="spinner fa fa-spinner fa-spin"></i>

        <iframe v-if="extension != 'docx'" v-bind:src="pathUrl" width="100%" height="310px" overflow='hidden' class="overflow-hidden"  />
        <div class="word-doc" v-if="extension == 'docx'">
          <h3>Can not display a Word document preview. Please use PDF files to enable content previews.</h3>
          <i class="icon fa fa-file-word-o" aria-hidden="true"></i>

        </div>
      </div>
      <!--FAILED-->
      <div v-if="isFailed">
        <h3>Uploaded failed.</h3>
        <pre>{{ uploadError }}</pre>
      </div>
    </div>

  </div>
</template>

<script>
import { upload, GetFile } from "@/assets/js/file-image.service"; // image service
import { wait } from "@/assets/js/utils";

import { mapGetters } from "vuex";

const STATUS_INITIAL = 0,
  STATUS_SAVING = 1,
  STATUS_SUCCESS = 2,
  STATUS_FAILED = 3;

export default {
  name: "evidence_upload",
  props: ["currentFile"],
  data() {
    return {
      uploadedFiles: [],
      uploadError: null,
      currentStatus: null,
      uploadFieldName: "photos",
      loading: false,
      pathUrl: null,
      extension: null
    };
  },
  computed: {
    ...mapGetters({ currentUser: "currentUser" }),
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    },
    displayFileName() {
      let str = this.currentFile;
      return str.substring(str.lastIndexOf("/") + 1);
    }
  },
  created() {
    if (this.currentFile.length > 0) {
      this.preload();
    }
  },
  methods: {
    preload() {
      this.currentStatus = STATUS_SUCCESS;
      this.loading = true;
      this.loadDocument(this.currentFile);
    },
    reset() {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL;
      this.uploadedFiles = [];
      this.uploadError = null;
    },
    clear() {
      this.reset();
      this.$emit("file", "");
    },
    loadDocument(path) {
      var ext = path.split(".").pop();
      this.extension = ext;

      GetFile.from(path)
        .then(f => {
          this.loading = false;
          this.pathUrl = f;
          this.currentStatus = STATUS_SUCCESS;
        })
        .catch(err => {
          console.log(err);
          this.currentStatus = STATUS_FAILED;
        });
    },
    save(formData) {
      // upload data to the server
      this.currentStatus = STATUS_SAVING;
      var itemId = this.currentUser.schoolID;

      upload(formData, itemId)
        .then(x => {
          this.uploadedFiles = [].concat(x);
          this.currentStatus = STATUS_SUCCESS;
          this.loading = true;

          this.$emit('fileuploaded', {"file": x.filename, "type": x.type});

          // load document
          this.loadDocument(x.path);
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
          //this.uploadError = err.response;
          this.currentStatus = STATUS_FAILED;
        });
    },
    filesChange(fieldName, fileList) {
      // handle file changes
      const formData = new FormData();

      if (!fileList.length) return;

      // append the files to FormData
      Array.from(Array(fileList.length).keys()).map(x => {
        formData.append(fieldName, fileList[x], fileList[x].name);
      });

      // save it
      this.save(formData);
    },
    openBrowse() {
      if ((this.currentStatus = STATUS_INITIAL)) {
        this.clear();
      } else {
        var elem = this.$refs.upBtn;
        elem.click();
      }
    }
  },
  mounted() {
    this.reset();
  }
};
</script>

<style scoped>

.feedback {
  height: 340px !important;
  padding-left: 20px;
  padding-top: 0px;
}

.container .input-file {
  opacity: 0;
  width: 41%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}

.success {
  text-align: center;
  overflow-x: hidden !important;
}

.success iframe {
  padding: 0px;
  marging: 0px;
}

.uploaded-msg {
  font-weight: 700;
  color: #7fc6a4;
}
</style>
